import {useMutation, jwtDecode} from "@ohoareau/apollo-client-jwt";
import {CREATE_AUTH_TOKEN} from '../configs/queries';
import {useCallback} from "react";
import useUser from "./useUser";

function useLogin() {
    const [, setUser] = useUser();
    const [mutate, infos] = useMutation<{createAuthToken: {token: string, refreshToken: string}}>(CREATE_AUTH_TOKEN);
    const login = useCallback(async data => {
        try {
            const r = await mutate({
                variables: {
                    data: {
                        username: data.username,
                        password: data.password,
                    }
                }
            });
            if (!r || !r.data || !r.data.createAuthToken || !r.data.createAuthToken.token) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`Unable to retrieve token`);
            }
            const user = await jwtDecode(r.data.createAuthToken.token) as object;
            await setUser({...user, token: r.data.createAuthToken.token, refreshToken: r.data.createAuthToken.refreshToken});
        } catch (e) {
            //
        }
    }, [mutate, setUser]);

    return [login, infos as any];
}

export default useLogin