import React from 'react';
import {Grid, Hidden} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LoginView from "../organisms/LoginView";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        '& .MuiTextField-root': {
            marginBottom: theme.spacing(1),
            width: '25ch',
        },
    },
    panel: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 100px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        zIndex: 10,
        padding: 64,
    },
    extra: {
        display: 'flex',
        opacity: 0.75,
        background: 'linear-gradient(90deg, #86A4FF 0%, #86A4FF 0%, #6788EE 0.01%, #4A73EE 49.48%, #2454E7 100%)',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
}))
function LoginScreen() {
    const classes = useStyles();
    return (
            <Grid container className={classes.root}>
                <Grid item lg={4} md={4} xs={12} className={classes.panel}>
                    <LoginView />
                </Grid>
                <Grid item lg={8} md={8} xs={12} className={classes.extra}>
                    <Hidden implementation={'css'} smDown={true}>
                        LOGIN IMAGE HERE
                    </Hidden>
                </Grid>
            </Grid>
    );
}

export default LoginScreen