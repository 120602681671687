import {useMutation} from "@ohoareau/apollo-client-jwt";
import {REGISTER} from '../configs/queries';
import {useCallback} from "react";

function useRegister() {
    const [mutate, infos] = useMutation<{register: {id: string}}>(REGISTER);
    const register = useCallback(async data => {
        try {
            const r = await mutate({
                variables: {
                    data: {
                        username: data.username,
                        password: data.password,
                        firstName: data.firstName,
                        lastName: data.lastName,
                    }
                }
            });
            if (!r || !r.data || !r.data.register || !r.data.register.id) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`Unable to retrieve user id`);
            }
            // @todo setUser
        } catch (e) {
            //
        }
    }, [mutate]);

    return [register, infos as any];
}

export default useRegister