import {useCallback} from "react";
import useUser from "./useUser";

function useLogout() {
    const [, setUser] = useUser();
    const logout = useCallback(async () => {
        await setUser(undefined);
    }, [setUser]);

    return [logout];
}

export default useLogout