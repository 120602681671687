export const routes = [
    {path: '/public/reports/:id', screen: 'Report', public: true},
    {path: '/public/invitations/:id', screen: 'Invitation', public: true},
    {path: '/catalyst/contents/:id', screen: 'CatalystContent'},
    {path: '/catalyst/:group', screen: 'Catalyst'},
    {path: '/catalyst', screen: 'Catalyst'},
    {path: '/podcasts', screen: 'Podcasts'},
    {path: '/settings', screen: 'Settings'},
    {path: '/challenges/:id/register', screen: 'ChallengeRegister'},
    {path: '/challenges/:id', screen: 'Challenge'},
    {path: '/challenges', screen: 'Challenges'},
    {path: '/masterclasses/:id/register', screen: 'MasterclassRegister'},
    {path: '/masterclasses/:id', screen: 'Masterclass'},
    {path: '/masterclasses', screen: 'Masterclasses'},
    {path: '/coaching', screen: 'Coaching'},
    {path: '/register', screen: 'Register', public: true},
    {path: '/', screen: 'Home', exact: true, public: true},
    {path: '/', screen: 'NotFound', public: true},
];

export default routes