/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    translation: {
        app_name: 'App',
        app_main_title: 'maars',
        home_welcome: 'Bienvenue sur maars',
        login_form_title: 'Connexion',
        login_form_description: 'Merci de saisir vos identifiants pour vous connecter',
        login_form_action_label: 'Connexion',
        new_password_form_title: 'Changement de mot de passe requis',
        new_password_form_description: 'Votre précédent mot de passe a expiré, merci de saisir un nouveau mot de passe pour vous connecter.',
        password_reset_form_title: 'Changement de mot de passe requis',
        password_reset_form_description: 'Une demande de changement de mot de passe a été initié pour votre compte, vous avez dû recevoir, par email, un code de sécurité à saisir ci-dessous en plus de votre nouveau mot de passe.',
        relation_accept_title: 'Invitation reçue',
        relation_accept_question: 'Acceptez-vous d\'être mis en relation et de partager vos coordonnées avec',
        relation_requester: '{{lastName}} {{firstName}}',
        constraints_required: 'obligatoire',
        error_validation: 'Merci de vérifier votre formulaire, un ou plusieurs champs ne sont pas valides',
        error_generic: "Une erreur inattendue est survenue, merci de contacter l'administrateur (erreur: {{message}})",
        error_forbidden: 'Identifiants non reconnus ou accès non autorisé',
        errors_unknown_query: "Requêtes GraphQL '{{query}}' inconnue (disponible: {{queryNames}})",
        role_user_label: 'Utilisateur',
        errors_auth_generic: 'Erreur de type {{type}} lors de l\'authentification',
        errors_auth_bad_credentials: 'Mot de passe incorrect pour {{username}}',
        errors_auth_unknown_user: 'Aucun compte utilisateur pour {{username}}',
        errors_auth_empty_user_context_for_new_password_completion: 'Votre requête de changement de mot de passe a expiré, merci de ré-itérer votre demande',
        errors_auth_empty_username_context_for_password_reset_completion: 'Votre requête de remise à zéro de votre mot de passe a expiré, merci de ré-itérer votre demande',
        errors_auth_missing_attributes_for_new_password_completion: 'Votre requête de changement de mot de passe a expiré, la confirmation n\'est plus possible, merci de ré-itérer votre demande',
        errors_auth_new_password_required: 'Vous devez changer le mot de passe pour {{username}}',
        errors_auth_password_policy_restriction: 'Le mot de passe saisi ne respecte pas les contraintes imposées par la politique de restriction pour les mots de passe imposée pour {{username}}{{description}}',
        errors_auth_password_reset_required: 'Une remise à zéro du mot de passe est nécessaire pour {{username}}',
        errors_auth_code_mismatch: 'Le code sécurité transmis n\'est pas le bon',
        errors_auth_limit_exceeded: 'Le nombre de tentative erronée maximum a été atteint, merci de contacter votre administrateur',
        errors_missing_groups: 'Vous ne disposez pas des autorisations nécessaires pour vous connecter sur cette application (vous n\'êtes pas {{missingGroups}})',
        errors_invitation_unknown: "L'invitation n'existe plus.",
        errors_invitation_generic: "Impossible d'accéder à cette invitation, une erreur inattendue est survenue (message: {{message}})",
        page_not_found_title: 'Page introuvable',
        page_denied_title: 'Accès non autorisé',
        page_unauthorized_title: 'Accès non autorisé',
        layouts_standard_draw_open_label: 'Ouvrir le volet',
        usermenu_logout_label: 'Déconnexion',
        usermenu_profile_label: 'Mon compte',
        languagemenu_en_label: 'English',
        languagemenu_fr_label: 'Français',
        loading_title: 'Chargement en cours...',
        page_not_found: 'Page introuvable :(',
        page_denied: 'Accès non autorisé.',
        constraints_count_not_a_number: "doit être un nombre",
        constraints_count_not_positive: "doit être supérieur à 0",
        constraints_count_too_high: "doit être inférieur à {{max}}",
        constraints_count_not_integer: "doit être un nombre entier",
        constraints_email: "email invalide",
        constraints_maxlength: "{{max}} caractères maximum",
        constraints_number: "doit être un nombre",
        constraints_password: "mot de passe non valide (8 caractères minimum, des chiffres, un caractère spécial, des majuscules, des minuscules)",
        constraints_security_code: "code de sécurité non valide (6 chiffres requis)",
        constraints_zip_code: "code postal non valide (5 chiffres requis)",
        constraints_phone: "numéro de téléphone invalide (+331... ou 01.... ou (+33)1....)",
        constraints_fax: "numéro de fax invalide (+331... ou 01.... ou (+33)1....)",
        constraints_mobilePhone: "numéro de téléphone mobile invalide (+336... ou 06.... ou (+33)6....)",
        constraints_url: "adresse internet non valide (doit commencer par http ou https)",
        constraints_maxitems: "maximum {{max}} {{itemTypePluralName}}",
        constraints_json: "erreur de syntaxe JSON",
        field_tags_label: "Tags",
        field_type_label: "Type",
        field_content_type_label: "Format",
        field_title_label: "Titre",
        field_language_label: "Langue",
        field_categories_label: "Catégories",
        field_role_label: "Rôle",
        field_description_label: "Description",
        field_file_label: "Fichier",
        field_code_label: "Code",
        field_status_label: "Statut",
        field_cedex_label: "Cedex",
        field_cedex_placeholder: "Ex: Cedex 15",
        field_city_label: "Ville",
        field_city_placeholder: "Ex: Colombel",
        field_complement_label: "Complément",
        field_complement_placeholder: "",
        field_count_label: "Nombre",
        field_count_placeholder: "Ex: 3",
        field_country_label: "Pays",
        field_country_placeholder: "Ex: France",
        field_date_label: "Date",
        field_date_placeholder: "",
        field_email_label: "Email",
        field_email_placeholder: "marie.dupont@monentreprise.fr",
        field_firstname_label: "Prénom",
        field_firstname_placeholder: "Marie",
        field_lastname_label: "Nom de famille",
        field_lastname_placeholder: "Dupont",
        field_name_label: "Nom",
        field_name_placeholder: "Nom",
        field_number_label: "Nombre",
        field_number_placeholder: "Ex: 1",
        field_password_label: "Mot de passe",
        field_password_placeholder: "Votre mot de passe personnel ici",
        field_phone_label: "Téléphone",
        field_phone_placeholder: "Ex: 0198765432",
        field_mobilePhone_label: "Téléphone Mobile",
        field_mobilePhone_placeholder: "Ex: 0612345678",
        field_securitycode_label: "Code de sécurité",
        field_securitycode_placeholder: "123456",
        field_reference_label: "Référence",
        field_reference_placeholder: "Ex: XC-123-A",
        field_siren_label: "SIREN",
        field_siren_placeholder: "Ex: 509703625",
        field_siret_label: "SIRET",
        field_siret_placeholder: "Ex: 50970362500020",
        field_street_label: "Adresse",
        field_street_placeholder: "Ex: 147, rue d'en Haut",
        field_text_label: "Titre",
        field_text_placeholder: "",
        field_username_label: "Email",
        field_username_placeholder: "marie.dupont@monentreprise.fr",
        field_zipcode_label: "Code postal",
        field_zipcode_placeholder: "Ex: 58350",
        field_linkedIn_label: "LinkedIn",
        field_website_label: "Site internet",
        field_url_label: "Adresse internet",
        field_content_url_label: "URL du contenu",
        field_comment_label: "Commentaire",
        field_linkedIn_placeholder: "Ex: https://linkedin.com/...",
        field_website_placeholder: "Ex: https://www.monentreprise.fr/...",
        field_comment_placeholder: "Ex: ...",
        field_accessmode_label: "Accessibilité",
        field_publishingdate_label: "Date de la première publication du contenu",
        field_widget_definition_label: "Définition du Widget",
        field_form_definition_label: "Définition du formulaire",
        menu_items_home: 'Accueil',
        buttons_accept_relation_label: 'Accepter',
        buttons_refuse_relation_label: 'Refuser',
        relation_accept_refused_message: 'Invitation refusée',
        relation_accept_accepted_message: 'Invitation acceptée',
        relation_accept_unknown_invitation: 'Invitation introuvable',
    },
}