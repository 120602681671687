import logo from "../assets/logo-maars.png";
import React from "react";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
    root: {
        marginBottom: 32,
    },
    large: {
        width: '90%',
    },
    medium: {
        width: '60%',
    },
    small: {
        width: '40%',
    }
});

function MaarsTitleLogo({size = 'large', className}: MaarsTitleLogoProps) {
    const classes = useStyles();

    return (
        <img src={logo} alt={'maars'} className={clsx(classes.root, classes[size], className)} />
    )
}

export interface MaarsTitleLogoProps {
    className?: string,
    size?: 'large' | 'medium' | 'small',
}

export default MaarsTitleLogo