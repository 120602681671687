import {useMutation} from "@ohoareau/apollo-client-jwt";
import {FORGOT_PASSWORD} from '../configs/queries';
import {useCallback} from "react";

function useForgotPassword() {
    const [mutate, infos] = useMutation<{forgotPassword: {id: string}}>(FORGOT_PASSWORD);
    const forgotPassword = useCallback(async data => {
        try {
            const r = await mutate({
                variables: {
                    data: {
                        username: data.username,
                        password: data.password,
                        firstName: data.firstName,
                        lastName: data.lastName,
                    }
                }
            });
            if (!r || !r.data || !r.data.forgotPassword || !r.data.forgotPassword.id) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`Unable to retrieve user id`);
            }
            // @todo
        } catch (e) {
            //
        }
    }, [mutate]);

    return [forgotPassword, infos as any];
}

export default useForgotPassword