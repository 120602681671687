/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    translation: {
        app_name: 'App',
        app_main_title: 'maars',
        home_welcome: 'Welcome on maars',
        help_welcome: 'How to gelp help on using maars',
        login_form_title: 'Login',
        login_form_description: 'Please fill in your credentials to login',
        login_form_action_label: 'Login',
        new_password_form_title: 'Password Change required',
        new_password_form_description: 'Your previous password has expired, please provide a new password to login',
        password_reset_form_title: 'Password Reset Confirmation',
        password_reset_form_description: 'You are someone has requested a password reset on this account. Please fill in the security code received on your device and the new password',
        relation_accept_question: 'Do you accept to be related with {{lastName}} {{firstName}} and share your contact details ?',
        constraints_required: 'required',
        error_validation: 'Please check your form, some fields are not valid',
        error_generic: 'There was an unexpected error, please contact your administrator (error: {{message}})',
        error_forbidden: 'Bad credentials and/or forbidden',
        errors_unknown_query: "Unknown GraphQL query '{{query}}' (available: {{queryNames}})",
        role_user_label: 'User',
        errors_auth_generic: 'Auth {{type}} error',
        errors_auth_bad_credentials: 'Bad credentials for user {{username}}',
        errors_auth_unknown_user: 'Unknown user {{username}}',
        errors_auth_empty_user_context_for_new_password_completion: 'Empty user context for new password completion',
        errors_auth_empty_username_context_for_password_reset_completion: 'Empty username context for password reset completion',
        errors_auth_missing_attributes_for_new_password_completion: 'Missing attributes {{attributes}} for new password completion of user {{username}}',
        errors_auth_new_password_required: 'New password required for user {{username}}',
        errors_auth_password_policy_restriction: 'Provided password does not comply with password policy restriction for user {{username}}{{description}}',
        errors_auth_password_reset_required: 'Password reset is required for {{username}}',
        errors_auth_code_mismatch: 'Invalid security code',
        errors_auth_limit_exceeded: 'Maximum number of failed attempts reached, please contact your administrator',
        errors_missing_groups: 'You are not allowed to access this application (missings {{missingGroups}} rights)',
        errors_invitation_unknown: "The invitation does not exist anymore.",
        errors_invitation_generic: "Unable to access this invitation, we faced an unexpected error (message: {{message}})",
        page_not_found_title: 'Not Found :(',
        page_denied_title: 'Denied Access',
        page_unauthorized_title: 'Unauthorized Access',
        layouts_standard_draw_open_label: 'Open drawer',
        usermenu_logout_label: 'Logout',
        usermenu_profile_label: 'My Profile',
        languagemenu_en_label: 'English',
        languagemenu_fr_label: 'Français',
        constraints_count_not_a_number: "must be a number",
        constraints_count_not_positive: "must be greater than 0",
        constraints_count_too_high: "must be less than {{max}}",
        constraints_count_not_integer: "must be a whole number",
        constraints_email: "invalid email address",
        constraints_maxlength: "must be {{max}} characters or less",
        constraints_number: "must be a number",
        constraints_password: "invalid password (min 8 characters, digits, special characters, upper case characters, lower case characters)",
        constraints_security_code: "invalid security code (6 digits required)",
        constraints_zip_code: "invalid zip code (5 digits required)",
        field_tags_label: "Tags",
        field_type_label: "Type",
        field_title_label: "Title",
        field_language_label: "Language",
        field_categories_label: "Categories",
        field_role_label: "Role",
        field_description_label: "Description",
        field_code_label: "Code",
        field_status_label: "Statut",
        field_cedex_label: "Cedex",
        field_cedex_placeholder: "Ex: Cedex 15",
        field_city_label: "City",
        field_city_placeholder: "Ex: Colombel",
        field_complement_label: "Complement",
        field_complement_placeholder: "",
        field_count_label: "Count",
        field_count_placeholder: "Ex: 3",
        field_country_label: "Country",
        field_country_placeholder: "Ex: France",
        field_date_label: "Date",
        field_date_placeholder: "",
        field_email_label: "Email",
        field_email_placeholder: "mary.smith@mycompany.com",
        field_firstname_label: "First Name",
        field_firstname_placeholder: "Mary",
        field_lastname_label: "Last Name",
        field_lastname_placeholder: "Smith",
        field_name_label: "Name",
        field_name_placeholder: "Name",
        field_number_label: "Number",
        field_number_placeholder: "Ex: 1",
        field_password_label: "Password",
        field_password_placeholder: "Your personal password here",
        field_phone_label: "Phone",
        field_phone_placeholder: "Ex: 0612345678",
        field_reference_label: "Reference",
        field_reference_placeholder: "Ex: XC-123-A",
        field_securitycode_label: "Security Code",
        field_securitycode_placeholder: "123456",
        field_siren_label: "SIREN",
        field_siren_placeholder: "Ex: 509703625",
        field_siret_label: "SIRET",
        field_siret_placeholder: "Ex: 50970362500020",
        field_vat_label: "N° TVA Intra",
        field_vat_placeholder: "Ex: FR74509703625",
        field_street_label: "Address",
        field_street_placeholder: "Ex: 147, rue d'en Haut",
        field_text_label: "Text",
        field_text_placeholder: "",
        field_username_label: "Email",
        field_username_placeholder: "mary.smith@mycompany.com",
        field_zipcode_label: "Zip code",
        field_zipcode_placeholder: "Ex: 58350",
        field_url_label: "URL",
        field_accessmode_label: "Content Access Type",
        field_publishingdate_label: "Publishing Date",
        field_definition_label: "Widget definition in JSON",
        menu_items_home: 'Home',
        buttons_accept_relation_label: 'Accept',
        buttons_refuse_relation_label: 'Refuse',
    },
}