import {Button, TextField} from "@material-ui/core";
import React, {MouseEventHandler} from "react";
import {makeStyles} from "@material-ui/core/styles";
import useForgotPassword from "../hooks/useForgotPassword";
import {useForm} from "react-hook-form";
import BasePanel from "./BasePanel";

const useStyles = makeStyles({
    forgotPassword_button: {
        marginTop: 32,
    },
    cancel_button: {
        marginTop: 64,
    }
});

function ForgotPasswordPanel({onLoginClick}: ForgotPasswordPanelProps) {
    const classes = useStyles();
    const [forgotPasswordUser, {error}] = useForgotPassword();
    const {register, handleSubmit} = useForm();
    return (
        <BasePanel onSubmit={handleSubmit(forgotPasswordUser)} title={'Mot de passe oublié'} error={error}>
            <TextField autoComplete={'username'} style={{width: '100%'}} fullWidth label={'Email'} placeholder={'moi@email.com'} variant={'standard'} required autoFocus name={'username'} type={'email'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <Button className={classes.forgotPassword_button} type={'submit'} variant={'contained'} color={'primary'} fullWidth>J'ai oublié mon mot de passe</Button>
            <Button className={classes.cancel_button} variant={'contained'} color={'default'} fullWidth onClick={onLoginClick}>Retour à la connexion</Button>
        </BasePanel>
    )
}

export interface ForgotPasswordPanelProps {
    onLoginClick: MouseEventHandler,
}

export default ForgotPasswordPanel