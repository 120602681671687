import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        padding: 8,
        marginBottom: 16,
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 'bold',
        opacity: 0.75,
    },
})
function ErrorPanel({error}: ErrorPanelProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {error.message}
        </div>
    )
}

export interface ErrorPanelProps {
    error?: any,
}

export default ErrorPanel