import {gql} from '@ohoareau/apollo-client-jwt';

export const GET_CURRENT_USER = gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            tags
            status
            role
        }
    }
`;

export const GET_CURRENT_USER_RECOMMENDED_FEED = gql`
    query getCurrentUserRecommendedFeed {
        getCurrentUser {
            id
            feed {
                recommended: group(id: "recommended") {
                    items {
                        id
                        score
                        comment
                        language
                        type
                        url
                        color
                        iconImage
                        category
                        subCategories
                        accessMode
                        provider
                        dsn
                        tags
                        sourcePublishedAt
                        lastVersion
                        code
                        title
                        description
                    }
                }
            }
        }
    }
`;

export const GET_CURRENT_USER_PILLAR_FEED = gql`
    query getCurrentUserPillarFeed($id: ID!) {
        getCurrentUser {
            id
            feed {
                pillar: group(id: $id) {
                    items {
                        id
                        score
                        comment
                        language
                        type
                        url
                        color
                        iconImage
                        category
                        subCategories
                        accessMode
                        provider
                        dsn
                        tags
                        sourcePublishedAt
                        lastVersion
                        code
                        title
                        description
                    }
                }
            }
        }
    }
`;

export const GET_INVITATION = gql`
    query getInvitation($id: ID!) {
        getInvitation(id: $id) {
            id
            createdAt
            updatedAt
            user
            userEmail
            userLastName
            userFirstName
            acceptedAt
            refusedAt
            status
            requestedBy
            requestedByEmail
            requestedByFirstName
            requestedByLastName
            email
            type
            target
        }
    }    
`
export const GET_REPORT = gql`
    query getReport($id: ID!) {
        getReport(id: $id) {
            id
        }
    }    
`

export const GET_CHALLENGE = gql`
    query getChallenge($id: ID!) {
        getChallenge(id: $id) {
            id
            code
            name
            status
        }
    }    
`

export const FIND_CHALLENGES = gql`
    query findChallenges {
        findChallenges {
            items {
                id
                code
                name
                status
                
            }
        }
    }    
`

export const GET_MASTERCLASS = gql`
    query getMasterclass($id: ID!) {
        getMasterclass(id: $id) {
            id
            code
            name
            status
        }
    }    
`

export const ACCEPT_INVITATION = gql`
    mutation acceptInvitation($id: ID!) {
        acceptInvitation(id: $id) {
            id
        }
    }   
`

export const REFUSE_INVITATION = gql`
    mutation refuseInvitation($id: ID!) {
        refuseInvitation(id: $id) {
            id
        }
    }   
`

export const CREATE_AUTH_TOKEN = gql`
    mutation createAuthToken($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }   
`

export const REFRESH_AUTH_TOKEN = gql`
    mutation refreshAuthToken($data: RefreshAuthTokenInput!) {
        refreshAuthToken(data: $data) {
            token
            refreshToken
        }
    }   
`

export const REGISTER = gql`
    mutation register($data: RegisterInput!) {
        register(data: $data) {
            id
        }
    }   
`

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($data: ForgotPasswordInput!) {
        forgotPassword(data: $data) {
            email
        }
    }
`