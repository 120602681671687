import {useCallback, useContext} from "react";
import UserContext from "../contexts/UserContext";

function useUser() {
    const {user, setUser} = useContext(UserContext);
    const changeUser = useCallback(user => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
        } else {
            localStorage.removeItem('user');
            setUser(undefined);
        }
    }, [setUser]);
    return [user, changeUser];
}

export default useUser