import React, {ComponentType} from 'react';
import {Route} from "react-router-dom";
import LoginScreen from './screens/LoginScreen';
import useUser from "./hooks/useUser";

function PrivateRoute({children, render, ...props}: PrivateRouteProps) {
    const [user] = useUser();
    if (user) return <Route render={render} {...props} />;
    return <Route {...props} component={LoginScreen} />;
}

export interface PrivateRouteProps {
    children?: JSX.Element,
    render?: any,
    component?: ComponentType,
}

export default PrivateRoute