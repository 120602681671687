import React, {lazy, Suspense, useCallback, useMemo, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import routes from './configs/routes';
import {ApolloProvider, createClient, jwtDecode} from '@ohoareau/apollo-client-jwt';
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";
import PrivateRoute from './PrivateRoute';
import i18next from 'i18next';
import {I18nextProvider, initReactI18next} from "react-i18next";
import translations from './configs/translations';
import themes from './configs/themes';
import config from './configs/config';
import {UserProvider} from './contexts/UserContext';
import {REFRESH_AUTH_TOKEN} from "./configs/queries";

function getScreenComponent(name: string) {
    return lazy(() => import(`./screens/${name}Screen`));
}

function i18nFactory({fallbackLng = 'fr', lng = "fr", resources = undefined}: any) {
    const i = i18next.createInstance();
    // noinspection JSIgnoredPromiseFromCall
    i
        .use(initReactI18next)
        .init({
            resources: resources || {},
            fallbackLng,
            lng,
            keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
            react: {
                wait: true,
            },
        })
    ;
    return i;
}

function App() {
    const [user, setUser] = useState(() => {
        const x = localStorage.getItem('user');
        return x ? JSON.parse(x) : undefined;
    });
    // @ts-ignore
    const theme = createMuiTheme(themes[config.theme]);
    const getCurrentTokens = useCallback(() => user ? {
        token: user.token,
        refreshToken: user.refreshToken,
    } : {}, [user]);
    const setCurrentTokens = useCallback(async (tokens: any) => {
        const user = await jwtDecode(tokens.token) as object;
        await setUser({...user, token: tokens.token, refreshToken: tokens.refreshToken});
    }, [setUser]);
    const refreshTokens = useCallback(async(refreshToken: string, client: {mutate: Function}) => {
        const r = await client.mutate({mutation: REFRESH_AUTH_TOKEN, variables: {data: {refreshToken}}});
        if (!r || !r.data || !r.data.refreshAuthToken) throw new Error(`Unable to refresh auth token`);
        return {
            token: r.data.refreshAuthToken.token,
            refreshToken: r.data.refreshAuthToken.refreshToken,
        }
    }, []);
    const onLogout = useCallback(async () => {
        await setUser(undefined)
    }, [setUser]);
    const onAuthError = useCallback(async () => {
        await setUser(undefined);
    }, [setUser]);

    const userProviderValue = useMemo(() => ({
        user,
        setUser,
    }), [user, setUser]);
    const client = createClient({
        getCurrentTokens,
        setCurrentTokens,
        refreshTokens,
        onLogout,
        onAuthError,
        uri: process.env.REACT_APP_API_ENDPOINT,
        timeout: 3000,
    });
    const i18n = i18nFactory({resources: translations});
    return (
        <ApolloProvider client={client}>
            <I18nextProvider i18n={i18n}>
                <MuiThemeProvider theme={theme}>
                    <UserProvider value={userProviderValue}>
                        <CssBaseline />
                        <Router>
                            <Suspense fallback={<div />}>
                                <Switch>
                                    {routes.map((route, i) => {
                                        const RouteComponent = route.public ? Route : PrivateRoute
                                        return (
                                            <RouteComponent key={i} path={route.path}
                                                            component={getScreenComponent(route.screen)}
                                                            exact={!!route.exact}
                                            />
                                        )
                                    })}
                                </Switch>
                            </Suspense>
                        </Router>
                    </UserProvider>
                </MuiThemeProvider>
            </I18nextProvider>
        </ApolloProvider>
    );
}

export default App;
