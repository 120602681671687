import MaarsTitleLogo from "../atoms/MaarsTitleLogo";
import ErrorPanel from "../atoms/ErrorPanel";
import React, {FormEventHandler} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    register_button: {
        marginTop: 32,
    },
    login_link: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.palette.primary.main,
        }
    },
    logo: {
        position: 'absolute',
        bottom: 0,
    },
    title: {
        fontFamily: '"Montserrat", sans-serif',
        marginBottom: 24,
    },
    form: {
        marginBottom: 128,
    }
}));

function BasePanel({onSubmit, title, error, children}: RegisterPanelProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <form onSubmit={onSubmit} className={classes.form}>
                <Typography variant={'h4'} className={classes.title}>{title}</Typography>
                {error && <ErrorPanel error={error} />}
                {children}
            </form>
            <MaarsTitleLogo size={'small'} className={classes.logo} />
        </div>
    )
}

export interface RegisterPanelProps {
    onSubmit: FormEventHandler,
    title: any,
    error?: any,
    children?: any,
}

export default BasePanel