import {Button, TextField} from "@material-ui/core";
import React, {MouseEventHandler} from "react";
import {makeStyles} from "@material-ui/core/styles";
import useRegister from "../hooks/useRegister";
import {useForm} from "react-hook-form";
import BasePanel from "./BasePanel";

const useStyles = makeStyles(theme => ({
    register_button: {
        marginTop: 32,
    },
    login_link: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.palette.primary.main,
        }
    },
    cancel_button: {
        marginTop: 64,
    }
}));

function RegisterPanel({onLoginClick}: RegisterPanelProps) {
    const classes = useStyles();
    const [registerUser, {error}] = useRegister();
    const {register, handleSubmit} = useForm();
    return (
        <BasePanel onSubmit={handleSubmit(registerUser)} title={'Inscription'} error={error}>
            <TextField autoComplete={'username'} style={{width: '100%'}} fullWidth label={'Email'} placeholder={'moi@email.com'} variant={'standard'} required autoFocus name={'username'} type={'email'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <TextField autoComplete={'none'} style={{width: '100%'}} fullWidth label={'Mot de passe'} placeholder={'MonMotdeP@sse'} variant={'standard'} required name={'password'} type={'password'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <TextField autoComplete={'firstName'} style={{width: '100%'}} fullWidth label={'Prénom'} placeholder={'Sophia'} variant={'standard'} required name={'firstName'} type={'text'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <TextField autoComplete={'lastName'} style={{width: '100%'}} fullWidth label={'Nom'} placeholder={'Yang'} variant={'standard'} required name={'lastName'} type={'text'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <Button className={classes.register_button} type={'submit'} variant={'contained'} color={'primary'} fullWidth>Je m'inscris gratuitement</Button>
            <Button className={classes.cancel_button} variant={'contained'} color={'default'} fullWidth onClick={onLoginClick}>J'ai déjà un compte</Button>
        </BasePanel>
    )
}

export interface RegisterPanelProps {
    onLoginClick: MouseEventHandler,
}

export default RegisterPanel