import Button from "@material-ui/core/Button";
import LoginPanel from "../molecules/LoginPanel";
import RegisterPanel from "../molecules/RegisterPanel";
import ForgotPasswordPanel from "../molecules/ForgotPasswordPanel";
import React, {MouseEventHandler, useCallback, useState} from "react";
import useUser from "../hooks/useUser";
import useLogout from "../hooks/useLogout";

function LoginView({defaultMode = 'login', onCancelClick}: LoginViewProps) {
    const [mode, setMode] = useState(defaultMode);
    const [user] = useUser();
    const [logout] = useLogout();
    const handleLogoutClick = useCallback(async e => {
        e.stopPropagation();
        await logout();
    }, [logout])
    const handleCancelClick = useCallback(async (e: any) => {
        onCancelClick && onCancelClick(e);
        !onCancelClick && e.stopPropagation();
    }, [onCancelClick]) as any;
    const handleRegisterClick = useCallback(async e => {
        e.stopPropagation();
        setMode('register');
    }, [setMode])
    const handleLoginClick = useCallback(async e => {
        e.stopPropagation();
        setMode('login');
    }, [setMode])
    const handleForgotPasswordClick = useCallback(async e => {
        e.stopPropagation();
        setMode('forgotPassword');
    }, [setMode])

    if ('login' === mode && !!user) return (
        <Button variant={'contained'} color={'primary'} fullWidth onClick={handleLogoutClick}>Déconnexion</Button>
    );
    if ('login' === mode && !user) return (
        <LoginPanel onCancelClick={onCancelClick ? handleCancelClick : undefined} onRegisterClick={handleRegisterClick} onForgotPasswordClick={handleForgotPasswordClick} />
    );
    if ('register' === mode) return (
        <RegisterPanel onLoginClick={handleLoginClick} />
    );
    if ('forgotPassword' === mode) return (
        <ForgotPasswordPanel onLoginClick={handleLoginClick} />
    );

    return null;
}

export interface LoginViewProps {
    onCancelClick?: MouseEventHandler,
    defaultMode?: 'login' | 'register' | 'forgotPassword',
}

export default LoginView