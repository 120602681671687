const theme = {
    typography: {
        fontFamily: "'Raleway', sans-serif",
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
    },
    shape: {
        borderRadius: 0,
    },
    palette: {
        appbar: {
            background: '#FFFFFF',
        }
    }
};

export default theme