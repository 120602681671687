import {Button, TextField} from "@material-ui/core";
import React, {MouseEventHandler} from "react";
import {makeStyles} from "@material-ui/core/styles";
import useLogin from "../hooks/useLogin";
import {useForm} from "react-hook-form";
import BasePanel from "./BasePanel";

const useStyles = makeStyles(theme => ({
    login_button: {
        marginTop: 32,
    },
    forgot_password_link: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'none',
            color: theme.palette.primary.main,
        }
    },
    register_button: {
        marginTop: 64,
    },
    cancel_button: {
        marginTop: 16,
    }
}));

function LoginPanel({onCancelClick, onRegisterClick, onForgotPasswordClick}: LoginPanelProps) {
    const classes = useStyles();
    const [login, {error, loading}] = useLogin();
    const {register, handleSubmit} = useForm();
    const onSubmit = async (data: any) => {
        await login({username: data.username, password: data.password})
    };
    return (
        <BasePanel onSubmit={handleSubmit(onSubmit)} title={'Connexion'} error={error}>
            <TextField style={{width: '100%'}} disabled={loading} autoComplete={'username'} fullWidth label={'Email'} placeholder={'moi@email.com'} variant={'standard'} required autoFocus name={'username'} type={'email'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <TextField style={{width: '100%'}} disabled={loading} autoComplete={'current-password'} fullWidth label={'Mot de passe'} placeholder={'MonMotdeP@sse'} variant={'standard'} required name={'password'} type={'password'} inputRef={register<any>({ required: true, maxLength: 80 })} />
            <p><span className={classes.forgot_password_link} onClick={onForgotPasswordClick}>J'ai oublié mon mot de passe</span></p>
            <Button disabled={loading} className={classes.login_button} type={'submit'} variant={'contained'} color={'primary'} fullWidth>Connexion</Button>
            {<Button disabled={loading} className={classes.register_button} variant={'contained'} color={'secondary'} fullWidth onClick={onRegisterClick}>Je n'ai pas de compte</Button>}
            {onCancelClick && <Button disabled={loading} className={classes.cancel_button} variant={'contained'} color={'default'} fullWidth onClick={onCancelClick}>Annuler</Button>}
        </BasePanel>
    )
}

export interface LoginPanelProps {
    onCancelClick: MouseEventHandler,
    onRegisterClick: MouseEventHandler,
    onForgotPasswordClick: MouseEventHandler,
}

export default LoginPanel